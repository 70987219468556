.App {
  text-align: center;
}

/* youtube video */

.headline {
  color: #ff8303;
}

.text {
  color: #f0e3ca;
}

.video-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.player-wrapper {
  width: 80vw;
  position: relative;
  padding-top: 56.25%/* Player ratio: 100 / (1280 / 720) */
}

.player-wrapper-double {
  width: 40vw;
  position: relative;
  padding-top: 56.25%/* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.image-and-text {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.img-style {
  max-width: 80vw;
  width: 40vw;
}

.paragraph-text {
  font-size: 1.1rem;
  line-height: 1.5rem;
  text-align: initial;
  padding: 0 2rem;
}

.card {
  background-color: #3c3932;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgb(191 174 174 / 20%);
  padding: 0.15rem;
  transition: 0.3s;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
}

.card-title {
  font-size: 1.40rem;
  margin-block-end: 0.5rem;
}

.btn-cta {
  text-decoration: none;
  padding: 4px 6px;
  display: block;
  line-height: 2;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
  background: #ff8303;
  color: #1b1a17;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  text-transform: uppercase;
  max-width: 80vw;
  width: 80vw;
}

.subtitle {
  width: 80vw;
  margin-block-start: 0.5rem;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.link-caption {
  font-size: 0.7rem;
  text-decoration: none;
  color: white;
}

.image-container-pp2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.container-double-videos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.subtitle-video {
  margin-block: 0.6rem;
}

.subtitle-bonus {
  margin-block: 0.5rem;
  font-size: 6rem;
}

.text-bonus {
  font-size: 1rem;
  padding: 0 8rem;
}

.icon-offer {
  width: 15vw;
}

.icon-boleto {
  width: 15vw;
  height: 36px;
}

.payment-icons {
  display: flex;
  justify-content: space-around;
}

.text-offer {
  font-size: 5rem;
  font-style: italic;
  margin-block: 0.2rem;
}

.image-offer {
  width: 70vw;
}

.list-parent {
  margin-inline-start: 1rem;
}

.list-items {
  text-align: left;
  font-size: 1.25rem;
}

.underline {
  text-decoration: underline;
}

.App-header {
  background-color: #1b1a17;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f0e3ca;
  text-align: center;
  padding: 0 6rem;
}

.font-price {
  font-size: 9rem;
}

.cents-price {
  font-size: 4rem;
}

@media (max-width: 768px) {
  .font-price {
    font-size: 6rem;
  }

  .cents-price {
    font-size: 0.8rem;
  }

  .image-and-text {
    grid-template-columns: 1fr;
  }
  .image-container {
    display: none;
  }
  .card-container {
    grid-template-columns: 1fr;
  }
  .btn-cta {
    width: -webkit-fill-available;
    font-size: 16px;
  }
  .container-double-videos {
    grid-template-columns: 1fr;
  }
  .player-wrapper-double {
    width: 80vw;
  }
  .App-header {
    padding: 0 1.15rem;
  }
}

.no-margin {
  margin: 0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fa-arrow-left::before {
  content: '<'
}

.fa-arrow-right::before {
  content: '>'
}
